<template>
  <!-- <div v-if="loadingState">loading...</div> -->
  <v-data-table
    :headers="headers"
    :items="items"
    sort-by="ID"
    :loading="loadingState"
    :search="search"
    multi-sort
    class="elevation-1"
    :footer-props="{
      showFirstLastPage: true,
      itemsPerPageText: 'Elemente pro Seite:',
      itemsPerPageAllText: 'Alle',
    }"
  >
    <template #[`footer.page-text`]="items">
      {{ items.pageStart }} - {{ items.pageStop }} / {{ items.itemsLength }}
    </template>

    <template #top>
      <v-toolbar flat dense short>
        <v-toolbar-title>{{ name }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche"
          single-line
          hide-details
        />
        <v-spacer />
        <v-btn color="primary" x-small fab @click="initialize">
          <v-icon medium> mdi-reload </v-icon>
        </v-btn>
        <v-divider vertical inset />
        <!-- START Benutzer-Editier-Dialog -->
        <v-dialog v-model="dialog" max-width="500px">
          <template #activator="{ on, attrs }">
            <v-btn color="primary" x-small fab v-bind="attrs" v-on="on">
              <v-icon medium> mdi-plus </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">{{ name }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model.number="editedItem.roleid"
                      label="ID Rolle"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model.number="editedItem.companyid"
                      label="CompanyID"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.username"
                      label="Username"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.email" label="E-Mail" />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="primary" text @click="close"> Abbrechen </v-btn>
              <v-btn color="primary" text @click="save"> Speichern </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- ENDE Benutzer-Editier-Dialog -->
        <!-- START Passwort-Reset-Dialog -->
        <v-dialog v-model="dialog_resetpassword" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline"
                >Passwort (zurück-)setzen:
                {{ editedItem_resetpassword.username }}</span
              >
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      id="new-password"
                      v-model="editedItem_resetpassword.newpassword"
                      label="Neues Passwort"
                      name="new-password"
                      prepend-icon="mdi-lock"
                      required
                      autofocus
                      :append-icon="pwsichtbar1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="pwsichtbar1 ? 'text' : 'password'"
                      @click:append="pwsichtbar1 = !pwsichtbar1"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      id="new-password-check"
                      v-model="editedItem_resetpassword.newpasswordcheck"
                      label="Neues Passwort erneut eingeben"
                      name="new-password-check"
                      prepend-icon="mdi-lock"
                      required
                      :append-icon="pwsichtbar2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="pwsichtbar2 ? 'text' : 'password'"
                      @click:append="pwsichtbar2 = !pwsichtbar2"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="primary" text @click="close_dialog_resetpassword">
                Abbrechen
              </v-btn>
              <v-btn color="primary" text @click="save_dialog_resetpassword">
                Passwort setzen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- ENDE Passwort-Reset-Dialog -->
      </v-toolbar>
    </template>
    <template #[`item.enabled`]="{ item }">
      <v-switch v-model="item.enabled" disabled height="0px"></v-switch>
    </template>
    <template #[`item.actions`]="{ item }">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-icon small class="mr-2" v-on="on" @click="editItem(item)">
            mdi-pencil
          </v-icon>
        </template>

        <span>Bearbeiten</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-icon small class="mr-2" v-on="on" @click="deleteItem(item)">
            mdi-delete
          </v-icon> </template
        ><span>Löschen</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-icon small class="mr-2" v-on="on" @click="setPassword(item)">
            mdi-form-textbox-password
          </v-icon> </template
        ><span>Passwort (zurück-)setzen</span>
      </v-tooltip>
    </template>
    <template #no-data>
      <v-btn color="primary" @click="initialize"> Reset </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  name: "UsersTable",
  data: () => ({
    name: "Benutzer",
    search: "",
    dialog: false,
    dialog_resetpassword: false,
    pwsichtbar1: false,
    pwsichtbar2: false,
    headers: [
      { text: "ID", align: "start", value: "ID" },
      { text: "ID Firma", value: "companyid" },
      { text: "ID Rolle", value: "roleid" },
      { text: "Benutzername", value: "username" },
      { text: "E-Mail-Adresse", value: "email" },
      { text: "Aktiviert", value: "enabled" },
      { text: "Aktionen", value: "actions", sortable: false },
    ],
    editedIndex_resetpassword: -1,
    editedItem_resetpassword: {},
    defaultItem_resetpassword: {
      newpassword: "",
      newpasswordcheck: "",
    },
    editedIndex: -1,
    editedItem: {},
    defaultItem: {
      companyid: null,
      name: "",
      email: "",
    },
  }),
  computed: {
    ...mapGetters("users", {
      loadingState: "loadingState",
      items: "items",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Neuer Benutzer" : "Bearbeiten";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialog_resetpassword(val) {
      val || this.close();
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch("users/updateData");
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    setPassword(item) {
      this.editedIndex_resetpassword = this.items.indexOf(item);
      this.editedItem_resetpassword = Object.assign({}, item);
      this.dialog_resetpassword = true;
    },
    async deleteItem(item) {
      const index = this.items.indexOf(item);
      await Swal.fire({
        title: "Sicher, dass der Benutzer gelöscht werden soll?",
        showDenyButton: true,
        confirmButtonText: `Löschen`,
        denyButtonText: `Abbrechen`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("users/delete", item);
          this.items.splice(index, 1);
          Swal.fire("Gelöscht!", "", "success");
        } else if (result.isDenied) {
          Swal.fire("Der Benutzer wurde nicht gelöscht", "", "info");
          return true;
        }
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    close_dialog_resetpassword() {
      this.dialog_resetpassword = false;
      this.$nextTick(() => {
        this.editedItem_resetpassword = Object.assign(
          {},
          this.defaultItem_resetpassword
        );
        this.editedIndex_resetpassword = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
      } else {
        this.$store.dispatch("users/add", this.editedItem);
      }
      this.close();
    },
    save_dialog_resetpassword() {
      Object.assign(
        this.items[this.editedIndex_resetpassword],
        this.editedItem_resetpassword
      );

      if (
        this.editedItem_resetpassword.newpassword !==
        this.editedItem_resetpassword.newpasswordcheck
      ) {
        Swal.fire({
          icon: "error",
          title: "Fehler",
          text: "Die eingegebenen Passwörter stimmen nicht überein!",
        });
      } else {
        const payload = {
          userid: this.editedItem_resetpassword.ID,
          newpassword: this.editedItem_resetpassword.newpassword,
        };
        this.$store.dispatch("users/resetPassword", payload);
        this.close_dialog_resetpassword();
      }
    },
  },
};
</script>
